var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Default", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("To implement a radio button you just add the "),
        _c("code", [_vm._v("vs-radio")]),
        _vm._v(" component and add a "),
        _c("code", [_vm._v("v-model")]),
      ]),
      _c(
        "vs-alert",
        {
          staticClass: "mt-5",
          attrs: { icon: "warning", active: "true", color: "warning" },
        },
        [
          _c("span", [
            _vm._v("The "),
            _c("strong", [_vm._v("Radio Button")]),
            _vm._v(" is always going to replace the current value."),
          ]),
        ]
      ),
      _c("ul", { staticClass: "demo-alignment" }, [
        _c(
          "li",
          [
            _c(
              "vs-radio",
              {
                attrs: { "vs-value": "luis" },
                model: {
                  value: _vm.radios1,
                  callback: function ($$v) {
                    _vm.radios1 = $$v
                  },
                  expression: "radios1",
                },
              },
              [_vm._v("Luis")]
            ),
          ],
          1
        ),
        _c(
          "li",
          [
            _c(
              "vs-radio",
              {
                attrs: { "vs-value": "carols" },
                model: {
                  value: _vm.radios1,
                  callback: function ($$v) {
                    _vm.radios1 = $$v
                  },
                  expression: "radios1",
                },
              },
              [_vm._v("Carols")]
            ),
          ],
          1
        ),
        _c(
          "li",
          [
            _c(
              "vs-radio",
              {
                attrs: { "vs-value": "summer" },
                model: {
                  value: _vm.radios1,
                  callback: function ($$v) {
                    _vm.radios1 = $$v
                  },
                  expression: "radios1",
                },
              },
              [_vm._v("Summer")]
            ),
          ],
          1
        ),
        _c(
          "li",
          [
            _c(
              "vs-radio",
              {
                attrs: { disabled: "true", "vs-value": "lyon" },
                model: {
                  value: _vm.radios1,
                  callback: function ($$v) {
                    _vm.radios1 = $$v
                  },
                  expression: "radios1",
                },
              },
              [_vm._v("Lyon - disabled")]
            ),
          ],
          1
        ),
        _c("li", { staticClass: "op-block" }, [
          _vm._v("\n                " + _vm._s(_vm.radios1) + "\n            "),
        ]),
      ]),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n\n<ul class="centerx">\n  <li>\n    <vs-radio v-model="radios1" vs-value="luis">Luis</vs-radio>\n  </li>\n  <li>\n    <vs-radio v-model="radios1" vs-value="carols">Carols</vs-radio>\n  </li>\n  <li>\n    <vs-radio v-model="radios1" vs-value="summer">Summer</vs-radio>\n  </li>\n  <li>\n    <vs-radio disabled="true" v-model="radios1" vs-value="lyon">Lyon - disabled</vs-radio>\n  </li>\n  <li class="modelx">\n    ' +
            _vm._s("{{ radios1 }}") +
            "\n  </li>\n</ul>\n\n<script>\nexport default {\n  data(){\n    return {\n      radios1:'luis',\n    }\n  }\n}\n</script>\n\n        "
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }