var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Color", "code-toggler": "" } },
    [
      _c(
        "p",
        [
          _vm._v("You can change the color with the property "),
          _c("code", [_vm._v("color")]),
          _vm._v(". You are able to use the "),
          _c("router-link", { attrs: { to: "/colors" } }, [
            _vm._v("Main Colors"),
          ]),
          _vm._v(" or "),
          _c("strong", [_vm._v("RGB")]),
          _vm._v(" and "),
          _c("strong", [_vm._v("HEX")]),
          _vm._v(" colors."),
        ],
        1
      ),
      _c(
        "vs-alert",
        {
          staticClass: "mt-5",
          attrs: { icon: "warning", active: "true", color: "warning" },
        },
        [
          _c("span", [
            _vm._v("Only "),
            _c("strong", [_vm._v("RGB")]),
            _vm._v(" and "),
            _c("strong", [_vm._v("HEX")]),
            _vm._v(" colors are supported."),
          ]),
        ]
      ),
      _c("ul", { staticClass: "demo-alignment" }, [
        _c(
          "li",
          [
            _c(
              "vs-radio",
              {
                attrs: { "vs-value": "primary" },
                model: {
                  value: _vm.radios2,
                  callback: function ($$v) {
                    _vm.radios2 = $$v
                  },
                  expression: "radios2",
                },
              },
              [_vm._v("Primary")]
            ),
          ],
          1
        ),
        _c(
          "li",
          [
            _c(
              "vs-radio",
              {
                attrs: { color: "success", "vs-value": "Success" },
                model: {
                  value: _vm.radios2,
                  callback: function ($$v) {
                    _vm.radios2 = $$v
                  },
                  expression: "radios2",
                },
              },
              [_vm._v("Success")]
            ),
          ],
          1
        ),
        _c(
          "li",
          [
            _c(
              "vs-radio",
              {
                attrs: { color: "danger", "vs-value": "Danger" },
                model: {
                  value: _vm.radios2,
                  callback: function ($$v) {
                    _vm.radios2 = $$v
                  },
                  expression: "radios2",
                },
              },
              [_vm._v("Danger")]
            ),
          ],
          1
        ),
        _c(
          "li",
          [
            _c(
              "vs-radio",
              {
                attrs: { color: "warning", "vs-value": "Warning" },
                model: {
                  value: _vm.radios2,
                  callback: function ($$v) {
                    _vm.radios2 = $$v
                  },
                  expression: "radios2",
                },
              },
              [_vm._v("Warning")]
            ),
          ],
          1
        ),
        _c(
          "li",
          [
            _c(
              "vs-radio",
              {
                attrs: { color: "dark", "vs-value": "Dark" },
                model: {
                  value: _vm.radios2,
                  callback: function ($$v) {
                    _vm.radios2 = $$v
                  },
                  expression: "radios2",
                },
              },
              [_vm._v("Dark")]
            ),
          ],
          1
        ),
        _c(
          "li",
          [
            _c(
              "vs-radio",
              {
                attrs: { color: "rgb(87, 251, 187)", "vs-value": "RGB" },
                model: {
                  value: _vm.radios2,
                  callback: function ($$v) {
                    _vm.radios2 = $$v
                  },
                  expression: "radios2",
                },
              },
              [_vm._v("RGB")]
            ),
          ],
          1
        ),
        _c(
          "li",
          [
            _c(
              "vs-radio",
              {
                attrs: { color: "#e48346", "vs-value": "HEX" },
                model: {
                  value: _vm.radios2,
                  callback: function ($$v) {
                    _vm.radios2 = $$v
                  },
                  expression: "radios2",
                },
              },
              [_vm._v("HEX")]
            ),
          ],
          1
        ),
        _c("li", { staticClass: "op-block" }, [
          _vm._v("\n                " + _vm._s(_vm.radios2) + "\n            "),
        ]),
      ]),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n\n<template>\n    <ul class="centerx">\n      <li>\n        <vs-radio v-model="radios2" vs-value="primary">Primary</vs-radio>\n      </li>\n      <li>\n        <vs-radio color="success" v-model="radios2" vs-value="Success">Success</vs-radio>\n      </li>\n      <li>\n        <vs-radio color="danger" v-model="radios2" vs-value="Danger">Danger</vs-radio>\n      </li>\n      <li>\n        <vs-radio color="warning" v-model="radios2" vs-value="Warning">Warning</vs-radio>\n      </li>\n      <li>\n        <vs-radio color="dark" v-model="radios2" vs-value="Dark">Dark</vs-radio>\n      </li>\n      <li>\n        <vs-radio color="rgb(87, 251, 187)" v-model="radios2" vs-value="RGB">RGB</vs-radio>\n      </li>\n      <li>\n        <vs-radio color="#e48346" v-model="radios2" vs-value="HEX">HEX</vs-radio>\n      </li>\n      <li class="modelx">\n        ' +
            _vm._s("{{ radios2 }}") +
            "}\n      </li>\n    </ul>\n</template>\n\n<script>\nexport default {\n  data(){\n    return {\n      radios2:'primary',\n    }\n  }\n}\n</script>\n\n        "
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }